import { Inject, Injectable, OnDestroy, PLATFORM_ID, PlatformRef } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Constants } from '../../../constants/Constants';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlatformDetectorService implements OnDestroy {
  private readonly breakpoints = Constants.Breakpoints;
  private resizeObserver: ResizeObserver | undefined;
  private _currentScreen$ = new BehaviorSubject<number | null>(null);
  isIOS: boolean;
  isMobileScreen: boolean;
  isDesktopScreen: boolean;
  isBrowser: boolean;
  isServer: boolean;
  // isGoogleBot: boolean;
  isDeskMid: boolean;
  isTabletScreen: boolean;
  currentScreen$: Observable<Screens> = this._currentScreen$
    .pipe(
      filter((width): width is number => width !== null),
      distinctUntilChanged(),
      map((width) => {
        switch (true) {
        case this.isBrowser && width <= this.breakpoints.md:
          return Screens.MOBILE;
        case this.isBrowser && width >= this.breakpoints.lg:
          return Screens.DESKTOP;
        case this.isBrowser && width >= this.breakpoints.md && width < this.breakpoints.xl:
          return Screens.TABLET;
        default:
          return Screens.UNKNOWN;
        }
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );

  constructor(
    @Inject(PLATFORM_ID) private platformId: PlatformRef,
    @Inject(DOCUMENT) private document: Document,
    // protected request: Request,
    //TODO - ask about it
    // @Optional() @Inject(Request) protected request: Request,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = !this.isBrowser;

    this.isIOS = this.isBrowser && /iPad|iPhone|iPod/.test(navigator.platform);
    this.isMobileScreen = this.isBrowser && window.innerWidth <= this.breakpoints.md;
    this.isDesktopScreen = this.isBrowser && window.innerWidth >= this.breakpoints.xl;
    this.isDeskMid = this.isBrowser && window.innerWidth >= this.breakpoints.xl && window.innerWidth < this.breakpoints.xxl;
    this.isTabletScreen = this.isBrowser && window.innerWidth >= this.breakpoints.md && window.innerWidth < this.breakpoints.xl;
    // this.isGoogleBot = this.isServer && this.request.header(Constants.X_KNOWN_BOT_HEADER) === 'true';

    this.resizeObserver = this.isBrowser
      ? new ResizeObserver(() => {
        this._currentScreen$.next((this.document.defaultView as Window).innerWidth);
      })
      : undefined;

    this.resizeObserver?.observe(this.document.body);
  }

  ngOnDestroy() {
    this.resizeObserver?.unobserve(this.document.body);
  }
}

export enum Screens {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  UNKNOWN = 'unknown'
}
